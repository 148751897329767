<!-- 充电记录 -->
<template>
  <div class="power_recode">
    <div class="power-list" v-if="list_show">
      <load-more
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        :totalCount="totalCount"
        @loadmore="loadmore"
      >
        <div
          class="power-item"
          v-for="(item, index) in power_list"
          :key="index"
          @click="to_power(item)"
        >
          <h3 v-if="item.pay == 0">扫码充电</h3>
          <h3 v-else>IC卡充电 <span>（卡号{{item.card}}）</span></h3>
          <p class="time">{{ item.created_at }}</p>
          <p class="addr">
            <i class="iconfont t-icon-dingwei-1"></i
            ><span>{{
              item.charger ? item.charger.address_detail : "暂无地址"
            }}</span>
          </p>
          <div class="mile">
            <p class="price">￥{{ item.money }}</p>
            <p class="refund" v-if="item.refund_money">
              (退款￥{{ item.refund_money || 0 }})
            </p>
            <p class="result">
              {{
                  item.status == 1
                  ? "正在充电"
                  : item.status == 2
                  ? "充电完成"
                  : "异常结束"
              }}
            </p>
          </div>
        </div>
      </load-more>
    </div>
    <div class="no-list" v-else>
      <img
        class="no-img"
        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101722.png"
      />
      <p class="no-txt">暂无充电记录哦~</p>
    </div>
  </div>
</template>

<script>
import { getData } from "@/api/user";
export default {
  data() {
    return {
      power_list: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      list_show: true,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  created() {
    this.get_power();
  },
  methods: {
    to_power(item) {
      this.$router.push({
        name: "power",
        query: {
          id: item.id,
          status: item.status
        },
      });
    },
    get_power() {
      getData({
        url: `api/charging/charger/charger_record?page=${this.pageIndex}`,
        data: {},
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.pageSize = data.per_page;
          this.totalCount = data.total;
          let list = data.data;
          if(list.length > 0) {
            list.forEach((v)=>{
              v.pay = v.consume_detail && v.consume_detail[0] && v.consume_detail[0].pay_type ? v.consume_detail[0].pay_type : 0
              v.card = v.consume_detail && v.consume_detail[0] && v.consume_detail[0].card_num ? v.consume_detail[0].card_num : ''
            })
          }
          if (this.pageIndex == 1) {
            this.power_list = list;
            this.list_show = list.length <= 0 ? false : true;
          } else {
            this.power_list = this.power_list.concat(list);
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    loadmore(pageIndex) {
      this.pageIndex = pageIndex;
      this.get_power();
    },
  },
};
</script>

<style lang="less">
.power_recode {
  .power-list {
    padding: 12px 12px 50px;
  }
  .power-item {
    position: relative;
    margin-bottom: 12px;
    padding: 19px 18px;
    background: #fff;
    font-size: 14px;
    color: #666;
    line-height: 20px;
    h3 {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #333;
      padding-right: 80px;
      span {
        font-size: 14px;
        color: #666;
        font-weight: 400;
      }
    }
    .time {
      margin-bottom: 9px;
    }
    .addr {
      display: flex;
      align-items: flex-start;
      padding-right: 70px;
      .iconfont {
        height: 12px;
        width: 12px;
        background-repeat: no-repeat;
        margin-right: 4px;
        margin-top: 3px;
      }
      span {
        flex: 1;
      }
    }
    .mile {
      position: absolute;
      right: 14px;
      top: 16px;
      text-align: right;
      .price {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #ffae15;
      }
      .refund {
        margin-top: 2px;
        font-weight: 600;
        color: #ffae15;
      }
      .result {
        margin-top: 2px;
        color: #3dc48d;
      }
    }
  }
}
</style>